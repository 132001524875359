'use client';

import React, { createContext, useContext, useState } from 'react';
import { AlertColor } from '@mui/material';

export type AlertContent = {
  severity: AlertColor;
  message: string;
  error?: unknown | undefined;
};

type AlertContextData = {
  alert: AlertContent | null;
  setAlert: (alert: AlertContent | null) => void;
};

export const AlertContext = createContext<AlertContextData>({
  alert: null,
  setAlert: (_) => {}
});

export function AlertProvider({
  children
}: React.PropsWithChildren): React.ReactElement {
  const [alert, _setAlert] = useState<AlertContent | null>(null);

  const setAlert = (alert: AlertContent | null) => {
    _setAlert(alert);
    if (alert?.error) {
      throw alert.error;
    }
  };

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export function useAlert() {
  return useContext(AlertContext);
}
