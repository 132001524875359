'use client';
import { createTheme } from '@mui/material/styles';
import { Roboto } from 'next/font/google';

export const roboto = Roboto({
  weight: ['400', '100', '300', '500', '700', '900'],
  subsets: ['latin']
});

export const theme = createTheme({
  palette: {
    primary: {
      main: '#689E41'
    },
    secondary: {
      main: '#CECECE'
    },
    background: {
      default: '#FFFFFF'
    },
    info: {
      main: '#CECECE'
    }
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    h6: {
      fontSize: 16,
      color: '#666666'
    },
    h5: {
      color: '#666666'
    },
    h4: {
      color: '#666666'
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#2A458D'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInput-root': {
            marginTop: '8px'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: '#2A458D'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: '#5470FF',
          fontSize: 16,
          textDecoration: 'underline',
          textTransform: 'none'
        },
        contained: {
          textTransform: 'none'
        },
        outlined: {
          textTransform: 'none'
        }
      }
    }
  }
});
